.loader17 {
  position: relative;
  width: 65px;
  border: 1px solid transparent;
  margin: 40px auto;
}

.loader17 span {
  position: absolute;
  bottom: 0;
  display: block;
  width: 9px;
  height: 5px;
  border-radius: 5px;
  background: rgba(0, 0, 0, 0.1);
  -webkit-animation: preloader 2s infinite ease-in-out;
  animation: preloader 2s infinite ease-in-out;
}
.loader17 span:nth-child(2) {
  left: 11px;
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}
.loader17 span:nth-child(3) {
  left: 22px;
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}
.loader17 span:nth-child(4) {
  left: 33px;
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}
.loader17 span:nth-child(5) {
  left: 44px;
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
}
.loader17 span:nth-child(6) {
  left: 55px;
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

@-webkit-keyframes preloader {
  0%,
  100%,
  50% {
    height: 5px;
    -webkit-transform: translateY(0);
    transform: translateY(0);
    background: rgba(0, 0, 0, 0.1);
  }
  25% {
    height: 30px;
    -webkit-transform: translateY(15px);
    transform: translateY(15px);
    background: #000;
  }
}
@keyframes preloader {
  0%,
  100%,
  50% {
    height: 5px;
    -webkit-transform: translateY(0);
    transform: translateY(0);
    background: rgba(0, 0, 0, 0.1);
  }
  25% {
    height: 30px;
    -webkit-transform: translateY(15px);
    transform: translateY(15px);
    background: #000;
  }
}
