.ant-layout {
  height: calc(var(--vh, 1vh) * 100);
  background: #f8f8fb !important;
}
.ant-layout-header {
  line-height: unset !important;
}
.pdf-modal,
.pdf-modal .ant-modal-content {
  padding: 0 !important;
}
.info-modal,
.info-modal .ant-modal-content {
  max-width: 450px !important;
  padding: 0 !important;
}
.info-modal {
  .ant-modal-header {
    height: 46px;
    padding: 20px;
  }

  .ant-modal-footer {
    padding: 20px;
  }
}

.ant-dropdown-menu-item {
  padding: 0 !important;
}
.ant-table-title {
  padding: 0 !important;
}
.ant-input-sm {
  padding-inline: 12px !important;
}
.ant-menu-inline {
  border-inline-end: none !important;
  background: none !important;
}
.ant-form-item-explain-error {
  font-size: 12px !important;
}
.ant-modal-content {
  overflow: scroll !important;
}
.ant-table-wrapper .ant-table-thead > tr > th,
.ant-table-wrapper .ant-table-thead > tr > td {
  background-color: #eff2f7 !important;
}
.ant-btn.bg-lightBlue:hover {
  background-color: #1865d8 !important;
}
.ant-btn.bg-warning:hover {
  background-color: #f1b44c !important;
}
.ant-btn-primary {
  box-shadow: none !important;
}
@media (max-width: 575px) {
  .ant-modal,
  .ant-modal-content {
    height: calc(var(--vh, 1vh) * 100);
    width: 100vw !important;
    margin: 0;
    top: 0;
    max-width: unset !important;
  }
  .ant-table-container {
    display: none !important;
  }
}
