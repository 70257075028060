.dialog-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fafbfd;
  border-bottom: 1px solid #dde1e4;
  height: 70px;
  padding-inline: 20px;
}
.dialog-body {
  overflow-y: auto;
  background-color: white;
  width: 450px;
}
.dialog-body .dialog-content,
.dialog-body .dialog-content-base {
  min-height: 280px;
}
.dialog-body .error-text {
  max-width: 400px;
  text-align: center;
}
.dialog-footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background: #fafbfd;
  padding: 16px 20px;
  border-top: 1px solid #dde1e4;
}
@media only screen and (max-width: 575px) {
  .dialog-header {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  }
  .dialog-footer {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  .dialog-body {
    width: unset;
  }
  .dialog-body .dialog-content {
    min-height: calc(100vh - 287px - 1rem);
    min-height: calc(var(--vh, 1vh) * 100 - 287px - 1rem);
  }
}
