.metismenu {
  margin: 0;
  padding-left: 0;
}

.metismenu li {
  display: flex;
  align-items: center;
  width: 100%;
}

#sidebar-menu ul li a {
  display: block;
  transition: all 0.4s;
  color: #424242;
  text-decoration: none;
  padding: .625rem 1.5rem;
  flex-grow: 1;
}

#sidebar-menu ul li a:hover {
  background-color: #ebebeb;
  border-radius: 5px;
}

.mm-active {
  background-color: #ebebeb;
  border-radius: 5px;
}

.ant-layout .ant-layout-header {
  height: 80px;
  border-bottom: 1px solid #dde1e4;
}

.ant-drawer .ant-drawer-header {
  padding-inline: 16px;
  height: 80px;
  min-height: 80px;
}

.ant-drawer .ant-drawer-body {
  padding: 0;
}

.ant-layout .ant-layout-sider {
  border-right: 1px solid #dde1e4;
  position: fixed;
  overflow: auto;
  left: 0;
  bottom: 0;
}

.acc_dropdown {
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border: 1px solid #dde1e4;
  border-radius: 50%;
}