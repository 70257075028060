body {
    margin: 0;
    background-color: #f8f8fb !important;
}

* {
    font-family: 'Poppins', sans-serif;
}

p {
    margin-block: 0px;
}

hr {
    height: 1px;
    background-color: #ccc;
    border: none;
}

.card-container {
    border: 1px solid #EFF0EF;
    border-radius: 8px;
    box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, .03);
    background-color: white;
}